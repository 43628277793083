<template>
  <Navigation yvisina=15>
    <template #atag>

      <a @click="$router.push('/restaurants/main/')" class="btn-getstarted scrollto"  style="color:White;font-size: 20px;"><i
          class="fa-solid fa-cart-plus"></i></a>
    </template>
    <template #content>
      <div style="padding-top: 110px;"></div>


      <section class="menu">
        <div class="container">
        
          <div class="food-menu wow fadeInUp">


            <br>
            <div class="row" id="paleopark">

              <span style="color:#0189a1;font-weight: 900;">{{ $t('x2') }} {{ formatTime(this.od) }} -
                {{ formatTime(this.do) }} </span>


              <div class="menu-item jelo col-sm-12 col-xs-12 starter" v-for="i in cart.items" :class="i.vrsta"
                v-show="i.vrsta == 'paleopark'">
                <div class="clearfix menu-wrapper">
                  <h4 class="testh4"><i
                      style="cursor:pointer;color:var(--color-primary);font-size: 25px;padding-right: 10px;"
                      class="fa-solid fa-square-plus" @click="addToCart(i.jelo, i.price, i.vrsta, i.id)"></i>
                    <i style="cursor:pointer;color:red;font-size: 25px;" class="fa-solid fa-square-minus"
                      @click="removeFromCart(i.jelo, i.price, i.quantity, i.id)"></i>

                    &nbsp;<span class="broj" :id=i.quantity
                      style="font-size:25px;padding-right:10px;">{{ i.quantity }}X</span> {{ i.jelo }} ({{ i.price }}€ x {{
                        i.quantity }}) &nbsp;&nbsp;
                  </h4>
                  <span class="price">{{ (i.price * i.quantity).toFixed(2) }}€</span>
                  <div class="dotted-bg"></div>
                </div>
              </div>

              <hr>
              <h4 class="testh4">Total&nbsp;&nbsp; {{ otherTotal }}€ &nbsp;&nbsp;</h4>
              <h4 class="testh4"></h4><br>
            </div>


            {{ vrijeme }}


            <br>
            <p style="color:gray;font-weight: bold;">Set order for later?</p>

            <button class="btn btn-outline-info" @click="buducadostava">{{ $t('12') }} </button><br><br>
            



            <div v-if="formopis.buduce" style="padding-bottom:20px;">
              <p style="color:#0189a1;margin-bottom:0px;">{{ $t('11') }} </p>
              <input type="datetime-local" id="datetimeInput" class="form-control" name="meeting-time"
                v-model="formopis.datumvrijeme" v-show="this.formopis.buduce">



            </div>
            <input type="text" :placeholder="'Any extra notes?'" class="form-control" v-model="formopis.opis"><br>

            <span>
            
            

              <button class="btn btn-primary" style="border:none; z-index:5;" id="payement" @click="openpaymentmodal"
                :disabled="buttonDisabled" v-show="true"><span v-if="rucak">{{ otherTotal }}€</span> Go to payment &nbsp;<i
                  class="fa fa-arrow-right"></i> </button>

              <br><br>
              <div v-show="false">
                <a href="https://diners.hr/"><img src="../assets/logotipi/diners_50.gif" alt=""></a>
              <a href="https://www.mastercard.com"><img src="../assets/logotipi/MasterCard50.gif" alt=""></a>
              <a href="https://www.americanexpress.com/"><img src="../assets/logotipi/AmericanExpress50.jpg" alt=""></a>

              <a href="https://www.visa.com/"><img src="../assets/logotipi/Visa50.gif" alt=""></a>
              <a href="https://www.mastercard.com/"><img src="../assets/logotipi/maestro50.gif" alt=""></a>
              <a href="https://www.discover.com/"><img src="../assets/logotipi/discover_50.gif" alt=""></a>
            
              <br><br>
              <a href="https://www.wspay.info/mastercard-securecode.html"><img src="../assets/logotipi/mastercheck.png"
                  style="height: 40px;" alt=""></a>
              <a href="https://diners.hr/"><img src="../assets/logotipi/dinersprotect.png" style="height: 40px;"
                  alt=""></a>
              <a href="https://www.wspay.info/verified-by-visa.html"><img src="../assets/logotipi/visasecure.png"
                  style="height: 40px;" alt=""></a>
              <a href="https://www.americanexpress.com/"><img src="../assets/logotipi/safekey.png" style="height: 40px;"
                  alt=""></a>
              <a><img src="../assets/logotipi/pcidss.png" style="height: 40px;" alt=""></a><br><br>
              <a href="http://www.wspay.info" title="WSpay - Web Secure Payment Gateway" target="_blank"><img
                  alt="WSpay - Web Secure Payment Gateway"
                  src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png" border="0"></a>
                </div>
          




            </span>
          </div>

        </div>

      </section><br>
      <footer id="footer" class="footer">

        <div class="footer-content">
          <div class="container">
            <div class="row">
              <h1>Contact area and payment info:</h1><br>
              <div class="col-lg-6 col-md-6">

                <b @click="togglepolicy('conversionpolicy')" style="cursor: pointer;color:gray;">{{
                  $t('conversionpolicy1') }} <i class="fa fa-arrow-down"></i></b><br><br>
                <p style="display:none;" id="conversionpolicy">{{ $t('conversionpolicy') }}</p>


                <b @click="togglepolicy('data')" style="cursor: pointer;color:gray;">{{ $t('datapolicy1') }} <i
                    class="fa fa-arrow-down"></i></b><br>
                <p style="display:none;" id="data">{{ $t('datapolicy') }}</p><br>
                <b @click="togglepolicy('security')" style="cursor: pointer;color:gray;">{{ $t('securitypolicy1') }} <i
                    class="fa fa-arrow-down"></i></b>
                <p style="display:none;" id="security">{{ $t('securitypolicy') }}</p><br><br>
                <b @click="togglepolicy('protection')" style="cursor: pointer;color:gray;">{{ $t('protection1') }} <i
                    class="fa fa-arrow-down"></i></b><br>
                <p style="display:none;" id="protection">{{ $t('protection') }}</p><br>
                <br>


              </div>


              <div class="col-lg-6 col-md-6">
                <div class="footer-info">
                  <h3>Mon Perin</h3>
                  <h3 style="font-size: 15px;">Lunch - Borgo Obrt za ugostiteljstvo</h3>
                  <p>

                  <p>San Pol 3 52211 Bale Croatia</p><br>


                  <strong>Telephone:</strong> 00385 52 824 338<br>
                  <strong>Email:</strong> info@monperin.hr<br>
                  <strong>Oib:</strong> 14967929073<br>
                  </p>
                </div>
              </div>




            </div>
          </div>
        </div>



      </footer><!-- End Footer -->
    </template>

  </Navigation>

  <div class="modal fade" id="dostavaform" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true"
    style="padding-top:50px;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title modelheading" id="deleteLabel">Choose payment type </h4>
          <i class="fa-solid fa-circle-xmark" style="color:#17a2b8;font-size: 30px;cursor: pointer;"  @click="this.$root.zatvoriform('dostavaform')" ></i>
  


        </div>
        <div class="modal-body">
  
            <div class="d-flex justify-content-center">
              <button  @click="this.$root.zatvoriform('dostavaform'); createDelivery('cash')"  class="btn btn-info f-left modalbutton" data-dismiss="modal" style="">Cash to personnel <i
              class="fa-solid fa-money-bill"></i></button>
              </div>
              <br>
              <p style="font-style: italic;font-size: 14px;">* Please try to prepare the exact amount.</p>
       </div>

  
      </div>
    </div>
  </div>
</template>
  
  
<style scoped>
.testh4 {
  font-size: 110%;
}
</style>
  
  
  
<script>
import axios from 'axios'
import Navigation from '../components/Navigation.vue'
export default {
  name: 'Info',
  components: {
    Navigation
  },

  data() {
    return {
      rucak: "",
      buttonDisabled: false,
      jela: [],
      total: "0",
      jelo: {
        id: "",
        vrsta: "0",
        quantity: "",

      },
      count: 1,
      formopis: {
        buduce: false,
        opis: '',
        cart: [],
        datumvrijeme: "",
        karticno: "Web Payment",
      },
      cart: {
        items: [],
        platform: "",
        facility: true,


      },
      od: 12,
      do: 20


    }
  },
  computed: {


    breakfastTotal() {
      let total = 0

      for (let i = 0; i < this.cart.items.length; i++) {
        if (this.cart.items[i].vrsta == "molpicio") {
          total += this.cart.items[i].price * parseInt(this.cart.items[i].quantity)
        }

      }

      return total.toFixed(2);

    },

    otherTotal() {
      
      console.log(this.cart.items)
      let total = 0
      console.log(total)
      for (let i = 0; i < this.cart.items.length; i++) {
        if (this.cart.items[i].vrsta == "paleopark") {
          total += this.cart.items[i].price * parseInt(this.cart.items[i].quantity)
        }

      }
      console.log(total)
      return total.toFixed(2);

    },

    vrijeme() {
      const sat = new Date(this.formopis.datumvrijeme).getHours() + 1
      const targetDate = new Date(this.formopis.datumvrijeme)


      if (sat) {
        if (sat >= this.od && sat <= this.do) {


          $("#paleopark").css('opacity', '1')

     
          this.rucak = true;

          this.buttonDisabled = false

        }

        else {
          $("#paleopark").css('opacity', '0.5')


          this.buttonDisabled = true
          //this.$root.toastnoti(this.$t('error'), "Lunch is served only between 1PM-10PM", "greska")
          this.$root.toastnoti(this.$t('error'), this.$t('2'), "greska")


        }
      }
      var now = new Date(this.formopis.datumvrijeme)
      var today = new Date();

      const timeDiffInMinutes = Math.floor((now - today) / (1000 * 60));

      if (timeDiffInMinutes <= -5) {
        this.$root.toastnoti(this.$t('error'), this.$t('3'), "greska")

      }

      if (now.getHours() < this.od || now.getHours() >= this.do) {
        this.$root.toastnoti(this.$t('error'), "No delivery is served at this time, schedule for later?", "greska")
        //this.$root.toastnoti(this.$t('error'), this.$t('4'), "greska")

      }


    },

  },


  methods: {
    formatTime(hour) {
      if (hour === 0 || hour === 24) {
        return '12am';
      } else if (hour === 12) {
        return '12pm';
      } else if (hour < 12) {
        return hour + 'am';
      } else {
        return (hour - 12) + 'pm';
      }
    },


    async getRadnoVrijeme() {
      await axios
        .get('/mpguest/radnovrijeme')
        .then(response => {


          this.od = response.data.lunchod
          this.do = response.data.lunchdo

        })
        .catch(error => {
          if (error.response.status === 401) {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            this.$store.commit('removeToken')
            //this.$root.toastnoti("Error", "Your account is not valid anymore, either your stay has come to the end or the password has been changed. If you are still with us, try to log in again.", "greska")
            this.$router.push('/bye/')
          }
        })
    },

    async togglepolicy(tekst) {
      $("#" + tekst).toggle();
    },
    buducadostava() {

      this.formopis.buduce = !this.formopis.buduce

      const date = new Date();
      const timezoneOffset = date.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
      const localTime = date.getTime() - timezoneOffset;
      this.formopis.datumvrijeme = new Date(localTime).toISOString().slice(0, 16)


    },
    methodThatForcesUpdate() {

      window.location.reload()
    },

    addToCart(jelo, price, vrsta, id) {
      const item = {
        jelo: jelo,
        price: price,
        quantity: "1",
        vrsta: vrsta,
        id: id,
      }

      this.$store.commit('addToCart', item)
      this.$root.toastnoti(this.$t('success'), this.$t('Dodano'), "info")

    },




    removeFromCart(jelo, price, quantity, id) {

      const item = {
        jelo: jelo,
        price: price,
        quantity: quantity,
        id: id,
      }

      this.$store.commit('removeFromCart', item)
      this.$root.toastnoti(this.$t('success'), this.$t('Maknuto'), "info")

    },


    createDelivery(vrsta) {



      this.buttonDisabled = true

      if (this.cartTotal == 0) {
        this.$root.toastnoti(this.$t('error'), this.$t('cartempty'), "greska")
        this.buttonDisabled = false
        return;
      }

      var now = new Date(this.formopis.datumvrijeme)
      var today = new Date();


      const timeDiffInMinutes = Math.floor((now - today) / (1000 * 60));

      if (timeDiffInMinutes <= -5) {
        this.$root.toastnoti(this.$t('error'), this.$t('3'), "greska")
        this.buttonDisabled = false
        return;
      }

      if (now.getHours() < this.od || now.getHours() >= this.do) {
        this.$root.toastnoti(this.$t('error'), "Lunch is not served at selected time", "greska")
        this.buttonDisabled = false
        return;
      }


      for (let i = 0; i < this.cart.items.length; i++) {
        if (this.cart.items[i].vrsta == "paleopark") {
          this.formopis.cart.push(this.cart.items[i])
       

        }


      }


      if (this.formopis.cart.length == 0) {
        this.$root.toastnoti("Error", this.$t('6'), "error")
        this.buttonDisabled = false
        return;
      }

      if(vrsta=="cash"){
        this.formopis.karticno = "cash"
        this.cashpayment()
      }
      else{
        this.cardpayment()
      }


      console.log(this.formopis)
    


    },

    cashpayment(){
      axios.post('/placanje/createdeliverycash/', this.formopis)
        .then((res) => {
          this.formopis.cart = []
    


          if (res.data == "Telefon") {
            this.$root.toastnoti(this.$t('error'), "Please fill in your telephone number at account page first", "error")
            return this.$router.push("/profile/")


          }
          else if (res.data == "ItemDoesNotExist") {
            this.$root.toastnoti(this.$t('error'), "Your cart has items that are no longer available. We emptied it, please try again.", "error")
            this.$store.commit('clearCart')
            return;
          }
          else {

            this.$router.push('/delivery/')
            this.$root.toastnoti(this.$t('success'), "Success, your delivery started", "uspjeh")


          }

          this.buttonDisabled = false


        })
        .catch((error) => {
          this.$root.toastnoti(this.$t('error'), "Error while making delivery, please contact us or try later", "error")
          if (error.response.status === 401) {
            this.$router.push('/bye/')
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            this.$store.commit('removeToken')
          }
          this.buttonDisabled = false

        }).finally(() => {
          //Perform action in always
        });
    },

    cardpayment(){
      axios.post('/placanje/createdeliverykartica/', this.formopis)
        .then((res) => {
          this.formopis.cart = []
    

          if (this.cartTotal == 0) {
        this.$root.toastnoti(this.$t('error'), this.$t('cartempty'), "greska")
        this.buttonDisabled = false
        return;
      }

          if (res.data == "Telefon") {
            this.$root.toastnoti(this.$t('error'), "Please fill in your telephone number at account page first", "error")
            return this.$router.push("/profile/")


          }
          else if (res.data == "ItemDoesNotExist") {
            this.$root.toastnoti(this.$t('error'), "Your cart has items that are no longer available. We emptied it, please try again.", "error")
            this.$store.commit('clearCart')
            return;
          }
          else {


            this.$root.toastnoti(this.$t('success'), this.$t('uspjehsuveniri'), "uspjeh")


            this.openPaymentForm(res.data.hash, res.data.cartid, res.data.shopid, res.data.total)
          }

          this.buttonDisabled = false


        })
        .catch((error) => {
          this.$root.toastnoti(this.$t('error'), "Error while making delivery, please contact us or try later", "error")
          if (error.response.status === 401) {
            this.$router.push('/bye/')
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            this.$store.commit('removeToken')
          }
          this.buttonDisabled = false

        }).finally(() => {
          //Perform action in always
        });
    },
    openPaymentForm(signature, cartid, shopid, total) {
      

      window.location.href = `https://mpguest.com/startpayment/${shopid}/${cartid}/${total}/${signature}`


    },
    openpaymentmodal() {
      
      var now = new Date(this.formopis.datumvrijeme)
      var today = new Date();


      const timeDiffInMinutes = Math.floor((now - today) / (1000 * 60));

      if (timeDiffInMinutes <= -5) {
        this.$root.toastnoti(this.$t('error'), this.$t('3'), "greska")
        this.buttonDisabled = false
        return;
      }

      if (now.getHours() < this.od || now.getHours() >= this.do) {
        this.$root.toastnoti(this.$t('error'), "Lunch is not served at selected time", "greska")
        this.buttonDisabled = false
        return;
      }

      
      this.$root.otvoriform('dostavaform')




    },



  },


  mounted() {

    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const localTime = date.getTime() - timezoneOffset;
    this.formopis.datumvrijeme = new Date(localTime).toISOString().slice(0, 16)
    this.cart = this.$store.state.cart

    const preloader = document.querySelector('#preloader');
    preloader.remove();
    this.platform = Capacitor.getPlatform()
    this.getRadnoVrijeme()


  },
}
</script>
  
  
